import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
   // 聊天机器人
   {
    path: '/',
    name: 'html',
    component: () => import('@/components/honee/index.vue')
  },
  {
    path: '/honee',
    name: 'honee',
    component: () => import('@/components/Html-w.vue')
  },
   // 聊天机器人
   {
    path: '/honnev',
    name: 'honnev',
    component: () => import('@/components/honee/honne.vue')
  },
   // 聊天机器人
   {
    path: '/iude',
    name: 'iude',
    component: () => import('@/components/iude.vue')
  },
   // 聊天机器人
   {
    path: '/function',
    name: 'function',
    component: () => import('@/components/function.vue')
  },
   {
    path: '/history',
    name: 'history',
    component: () => import('@/components/history/index.vue')
  },
 
   {
    path: '/historydetails',
    name: 'historydetails',
    component: () => import('@/components/history/details.vue')
  },
 
   {
    path: '/homra',
    name: 'homra',
    component: () => import('@/components/honee/homra.vue')
  },
   {
    path: '/studentWork',
    name: 'studentWork',
    component: () => import('@/components/studentWork/index.vue')
  },
   {
    path: '/studentHomeworkDetails',
    name: 'studentHomeworkDetails',
    component: () => import('@/components/studentWork/studentWork.vue')
  },
   {
    path: '/studentDetailsContent',
    name: 'studentDetailsContent',
    component: () => import('@/components/studentWork/studentDetailsContent.vue')
  },
   {
    path: '/viewStudentDetails',
    name: 'viewStudentDetails',
    component: () => import('@/components/studentWork/viewStudentDetails.vue')
  },
   {
    path: '/studentHomeworkGrading',
    name: 'studentHomeworkGrading',
    component: () => import('@/components/studentWork/studentHomeworkGrading.vue')
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;